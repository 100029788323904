<template>
  <pro-menu-layout>
    <div class="q-pa-md q-gutter-y-md">
      <div>
        <div class="q-mb-md text-h4">
          {{ getRes("Form.Section.TenderClarification") }}
        </div>
        <pro-query-audit-log-details
          :processId="$route.params.processId"
          :attachments="attachments"
        />
      </div>
      <div>
        <pro-deck
          :title="getRes('Form.Field.Responses')"
          :cards="responses"
          v-slot="{ card }"
        >
          <pro-deck-card :title="card.title" :actions="card.actions" expandable>
            <pro-query-details
              :queryText="card.queryText"
              :attachments="card.attachments"
            />
          </pro-deck-card>
        </pro-deck>
      </div>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProQueryAuditLogDetails from "@/components/PROSmart/ProQueryAuditLogDetails";
import ProQueryDetails from "@/components/PROSmart/ProQueryDetails";

export default {
  components: {
    ProMenuLayout,
    ProDeck,
    ProDeckCard,
    ProQueryAuditLogDetails,
    ProQueryDetails,
  },
  data() {
    return {
      attachments: [],
      responses: [],
    };
  },
  created() {
    this.$proSmart.tender
      .getTenderClarificationDetailsAndResponseList(
        this,
        this.$route.params.id,
        this.$route.params.queryId,
        this.$route.params.tendererId
      )
      .then((clarification) => {
        this.attachments = clarification.tenderClarification.attachments;

        this.responses = clarification.queryResponses.map((response) => ({
          key: response.queryId,
          title: this.$proSmart.common.format.dateTime(
            new Date(response.dateSubmitted)
          ),
          queryText: response.queryText,
          attachments: response.attachments,
          actions: [
            {
              processId: response.processId,
            },
          ],
        }));
      });
  },
};
</script>
